<template>
    <div class="lds-ring" :class="dark ? 'dark' : ''" :style="{ 'font-size': size ? size : '1em' }">
        <div v-for="i in 4" :key="'loader-' + i"></div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Loader',
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: null,
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';
@import 'Scss/runtime.scss';

.lds-ring {
    display: inline-block;
    position: relative;
    width: 1em;
    height: 1em;
    div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 1em;
        height: 1em;
        margin: 0px;
        border-width: 0.15em;
        border-style: solid;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: $white transparent transparent transparent;

        &:nth-child(1) {
            animation-delay: -0.45s;
        }
        &:nth-child(2) {
            animation-delay: -0.3s;
        }
        &:nth-child(3) {
            animation-delay: -0.15s;
        }
    }
    &.dark div {
        border-color: $primary transparent transparent transparent;
    }
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
